import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import CampaignSection from '../../components/CarryAll/CampaignSection'
import DetailsSection from '../../components/CarryAll/DetailsSection'
import ExploreSection from '../../components/CarryAll/ExploreSection'
import GallerySection from '../../components/CarryAll/GallerySection'
import SelectorSection from '../../components/CarryAll/SelectorSection'
import SliderSection from '../../components/CarryAll/SliderSection'
import Layout from '../../components/Layout'
import PersonliseModal from '../../components/Personalisation'
import Reviews from '../../components/Reviews'
import SetModal from '../../components/SetModal/new'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import useLocalisation from '../../helper/useLocalisation'
import usePersonalisationParams from '../../helper/usePersonalisationParams'
import useProductData from '../../helper/useProductData'
import useSelectedColor from '../../helper/useSelectedColor'
import useTracking from '../../helper/useTracking'

// Landing
const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const List = styled.ul`
  list-style: circle !important;
  & li {
    list-style: circle !important;
    margin-bottom: 8px;
  }
`

const count = ['01', '02', '03', '04', '05', '06', '07', '08']
const colors = ['charcoal', 'forest', 'chalk', 'navy']
const colorsAll = {
  'midnight-black': 0,
  'forest-green': 1,
  'chalk-white': 2,
  'french-navy': 3,
}
const names = Object.keys(colorsAll)

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const skus = ['TOT02-CHA', 'TOT03-FOR', 'TOT04-NUD', 'TOT01-NAV']

const CarryAll = ({ data, pageContext, location }) => {
  const context = useContext(StoreContext)
  let { currency, setScroll, addVariantToCart, openCart, language } = context

  let [personaliseOpen, setPersonaliseOpen] = useState(undefined)
  let [collectionOpen, setCollectionOpen] = useState(false)
  let [labelData, setLabelData] = useState({})

  const personalised = isEmpty(labelData)

  const {
    title,
    price,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    oosData,
  } = useProductData(data.allCockpitProducts, personalised)
  const [selectedColor, setSelectedColor] = useSelectedColor(
    location.search,
    0,
    names.map((val) => val.toLowerCase()),
    oosData
  )
  usePersonalisationParams(location.search)

  const varients = shopifyIds
  const images_names = colors.map((color_val) =>
    count.map((count_val) => {
      return `ecom_${count_val}_${color_val}`
    })
  )
  const images = images_names.map((second) => [
    data.CamGal,
    ...second.map((name) => data[name]),
    data.Strap,
  ])

  useEffect(() => {
    setScroll(!personaliseOpen)
    if (personaliseOpen === false && window.innerWidth < 900) {
      setTimeout(() => {
        const titleDiv = document.getElementById('selector-top')
        const style = getComputedStyle(titleDiv)
        window.scrollTo(0, titleDiv.offsetTop - parseInt(style.marginTop))
      }, 10)
    }
  }, [personaliseOpen, setScroll])

  const [trackAddToCart, updateItem] = useTracking({
    title: 'Carry All Tote',
    id: fbIDs[selectedColor] && fbIDs[selectedColor].split('_')[2],
    imageUrl: '',
    url: 'https://july.com/travel-bags/carry-all-tote/',
    price: parseInt((price?.match(/\d+/g) || []).join('')),
    sku: skus[selectedColor],
  })

  useEffect(() => {
    updateItem({
      title: 'Carry All Tote',
      id: fbIDs[selectedColor] && fbIDs[selectedColor].split('_')[2],
      fb_id: fbIDs[selectedColor],
      price: parseInt((price?.match(/\d+/g) || []).join('')),
      url: 'https://july.com/travel-bags/carry-all-tote/',
      imageUrl: '',
      sku: skus[selectedColor],
      variant: colors[selectedColor],
    })
  }, [selectedColor, fbIDs])

  const handleAddToCart = async (e) => {
    e.preventDefault()

    const backendLabelData = !personalised
      ? { ...labelData, font: labelData.font.toLowerCase() }
      : {}
    await addVariantToCart([varients[selectedColor]], 1, backendLabelData)
    fbq(currency, 'AddToCart', {
      content_name: 'Tote',
      content_category: 'Luggage & Bags',
      content_ids: [fbIDs[selectedColor]],
      content_type: 'product',
      value: parseInt((price?.match(/\d+/g) || []).join('')),
      currency: context.language.replace('root', 'au').toUpperCase() + 'D',
    })
    trackAddToCart()
    openCart()
  }

  const Q = useLocalisation()

  return (
    <>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.fixed?.srcWebp
        }
        productData={{
          name: 'Carry All Tote',
          imageSrc: '',
          description: `The July Carry All Tote is a slim tote bag that takes you from hotel to home. Minimal design with every detail considered, it will be your companion for your everyday travels.`,
          price: parseInt((price?.match(/\d+/g) || []).join('')),
          sku: 'TOT01-NAV',
          handle: 'carry-all-tote',
        }}
        reviewData={{
          value: '5',
          count: '5',
        }}
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PersonliseModal
              handleSave={(data) => {
                setPersonaliseOpen(false)
                setLabelData(data)
              }}
              handleClose={() => setPersonaliseOpen(false)}
              initialValues={labelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="Tote"
            />
          </div>
        )}
      </div>
      <div style={{ display: collectionOpen ? 'block' : 'none' }}>
        <SetModal
          handle="/travel-bags/carry-all-tote"
          handleClose={() => {
            setCollectionOpen(false)
          }}
          labelData={labelData}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </div>
      <div style={{ display: collectionOpen ? 'none' : 'block' }}>
        <Layout
          facebookCaseId={fbIDs[selectedColor]}
          location="product"
          context={context}
        >
          <LandingContainer>
            <GallerySection
              selectedColor={selectedColor}
              images={images[selectedColor]}
              videos={[
                {
                  video: 'tote.mp4',
                  thumbnail: data.video_thumb,
                },
                {
                  video: 'ecom_videos/tote.mp4',
                  thumbnail: data.video_thumb2,
                },
                {
                  video: 'ecom_videos/tote_2.mp4',
                  thumbnail: data.video_thumb3,
                },
              ]}
              defaultImage={1}
              campaign={true}
              campaignText={undefined}
            />
            <SelectorSection
              name={title}
              price={price}
              reviewId={reviewId}
              freePersonalisation={freePersonalisation}
              oosData={oosData}
              outline={description}
              outlineSub={overview}
              details={specs}
              handleAddToCart={handleAddToCart}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleBefore={() => {}}
              lifetime={true}
              isLuggage={true}
              setCollectionOpen={setCollectionOpen}
              setPersonaliseOpen={setPersonaliseOpen}
              labelData={labelData}
              personalised={personalised}
              freeBottle={true}
            />
          </LandingContainer>
          <SliderSection
            data={[
              {
                title: (
                  <>
                    Introducing QuickPass
                    <sup style={{ fontSize: 8 }}>™</sup>
                  </>
                ),
                sub: <>IN AND OUT FAST</>,
                image: language === 'au' ? data.QuickPass : data.QuickPassUS,
                body: (
                  <>
                    A hidden secure pocket with magnetic closure to access
                    important things, like your passport or your phone, with one
                    hand - no zipping needed.
                  </>
                ),
              },
              {
                title: 'A space for everything. ',
                sub: <>PERFECT POCKETS</>,
                image: data.Space,
                body: (
                  <>
                    Four key pocket areas mean you can access everything you
                    need for work, travel, or everyday. Front pockets for small
                    things. Main compartment for the bulk of your carry. Quilted
                    padded laptop compartment for access and protection.
                    QuickPass
                    <sup style={{ fontSize: 8 }}>™</sup> pocket for speedy
                    access to your most important items.
                  </>
                ),
              },
              {
                title: 'Easy travel.',
                sub: <>ALWAYS SECURE</>,
                image: data.Pass,
                body: (
                  <>
                    The Carry All Tote has a pass-through band for easy
                    attachment to your luggage, and plenty of pockets to hold
                    everything while moving. Including quick access to your
                    laptop for screening.
                  </>
                ),
              },
              {
                title: 'Always comfortable to hold.',
                sub: <>IT'S ALL IN THE DETAILS</>,
                image: data.Quilted,
                body: (
                  <>
                    You have 3 ways to carry the Tote so you’re always
                    comfortable no matter where you’re heading: clip the loops
                    together to hold it in the palm of your hand, pull the
                    handle loops over your shoulder, or use the included
                    adjustable shoulder strap with padded leather for hands-free
                    movement.
                  </>
                ),
              },
              {
                title: '5 year guarantee.',
                sub: <>MADE FOR THE WORLD</>,
                image: data.Year,
                padding: true,
                body: (
                  <>
                    We stand by the quality of every July product with unmatched
                    warranty commitments. <br /> <br />
                    On any manufacturing fault, anywhere you are in the world,
                    July will repair or replace your product for you. Our Carry
                    All range has a 5 year quality guarantee.
                  </>
                ),
              },
            ]}
          />
          <CampaignSection image={data.Cam.childImageSharp.fluid} />
          <DetailsSection data={data} />
          <ExploreSection
            firstPanel={{
              title: 'Backpack.',
              image: data.backpack,
              to: '/travel-bags/carry-all-backpack',
            }}
            secondPanel={{
              title: 'Weekender.',
              image: data.weekender,
              to: '/travel-bags/carry-all-weekender-duffle-bag',
            }}
          />
          {reviewId && <Reviews productId={reviewId} />}
        </Layout>
      </div>
    </>
  )
}

export default CarryAll

export const query = graphql`
  query {
    SEO: cockpitPage(
      cannonical_url: { value: { eq: "/travel-bags/carry-all-tote" } }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }

      TEST_banner_image {
        value {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    allCockpitProducts(
      filter: { handle: { value: { eq: "/travel-bags/carry-all-tote" } } }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    Nesting: file(relativePath: { eq: "buy/nesting.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    CamGal: file(relativePath: { eq: "carry_all/cam/cam/2.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Strap: file(relativePath: { eq: "carry_all/ecom2/tote/00.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Pass: file(relativePath: { eq: "carry_all/pass/tote.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Cam: file(relativePath: { eq: "carry_all/cam_banner.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    weekender: file(relativePath: { eq: "carry_all/nav/weekender.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tote: file(relativePath: { eq: "carry_all/nav/tote.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    backpack: file(relativePath: { eq: "carry_all/nav/backpack.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    QuickPass: file(relativePath: { eq: "carry_all/etch_com/10.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    QuickPassUS: file(relativePath: { eq: "us_tote.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Space: file(relativePath: { eq: "carry_all/etch_com/05.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Quilted: file(relativePath: { eq: "carry_all/etch_com/04.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Gym: file(relativePath: { eq: "carry_all/etch_com/02.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Year: file(relativePath: { eq: "carry_all/cam/cam/5.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    video_thumb: file(relativePath: { eq: "carry_all/video_load/tote.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    video_thumb2: file(relativePath: { eq: "ecom_videos/tote.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    video_thumb3: file(relativePath: { eq: "ecom_videos/tote_2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_01_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/01_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_01_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/01_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_01_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/01_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_01_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/01_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_02_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/02_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_02_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/02_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_02_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/02_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_02_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/02_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_03_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/03_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_03_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/03_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_03_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/03_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_03_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/03_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_04_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/04_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_04_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/04_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_04_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/04_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_04_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/04_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_05_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/05_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_05_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/05_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_05_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/05_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_05_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/05_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_06_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/06_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_06_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/06_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_06_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/06_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_06_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/06_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_07_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/07_chalk.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_07_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/07_charcoal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_07_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/07_forest.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_07_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/07_navy.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ecom_08_chalk: file(
      relativePath: { eq: "carry_all/ecom2/tote/08_chalk_1.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_08_charcoal: file(
      relativePath: { eq: "carry_all/ecom2/tote/08_charcoal_1.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_08_forest: file(
      relativePath: { eq: "carry_all/ecom2/tote/08_forest_1.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecom_08_navy: file(
      relativePath: { eq: "carry_all/ecom2/tote/08_navy_1.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
